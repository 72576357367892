import {
  EntityId,
  OrientationType,
  PrintServiceProductEntity,
  PrintServiceProductImageEntity,
} from "@jackfruit/common"
import { PayloadAction } from "@reduxjs/toolkit"
import { uniq } from "lodash"
import { SagaIterator } from "redux-saga"
import { all, call, put, select, takeEvery } from "redux-saga/effects"
import { ImageEntity } from "~/interfaces/entities/Image"
import { ImageRegionEntity } from "~/interfaces/entities/ImageRegion"
import { LineItemEntity } from "~/interfaces/entities/LineItem"
import { ProductPageEntity } from "~/interfaces/entities/ProductPage"
import { UploadEntity } from "~/interfaces/entities/Upload"
import {
  getDefaultImageTransformation,
  getOrientationDimensions,
} from "~/services/Utils"
import { actions, UpdateLineItemOrientationPayload } from "../process"
import { imageRegions } from "../state/imageRegions"
import { imageTransformations } from "../state/imageTransformations"
import { lineItems } from "../state/lineItems"
import { printServiceProductImagesSelector } from "../state/printServiceProductImages"
import { printServiceProductsSelector } from "../state/printServiceProducts"
import { productPages } from "../state/productPages"
import { RootState } from "../store"
import { getImageRegion } from "./imageRegion"
import { getImage } from "./images"
import { getLineItem } from "./lineItems"
import { getProductPage } from "./productPages"
import { getUpload } from "./uploads"

export default function* watchUpdateLineItemOrientation() {
  yield takeEvery(
    actions.updateLineItemOrientation.type,
    processUpdateLineItemOrientation
  )
}

function* processUpdateLineItemOrientation(
  action: PayloadAction<UpdateLineItemOrientationPayload>
): SagaIterator {
  const { lineItemId, orientation } = action.payload
  const lineItem: LineItemEntity = yield call(getLineItem, lineItemId)

  if (lineItem.orientation !== orientation) {
    yield put(
      lineItems.actions.updateOne({
        id: lineItemId,
        changes: {
          orientation,
        },
      })
    )

    yield all(
      lineItem.productPageIds.map(productPageId =>
        call(switchPageOrientation, {
          productPageId,
          orientation,
        })
      )
    )
  }
}

export function* switchPageOrientation(payload: {
  productPageId: EntityId
  orientation: OrientationType
}): SagaIterator<void> {
  const { productPageId, orientation } = payload
  const productPage: ProductPageEntity = yield call(
    getProductPage,
    productPageId
  )

  // invert width and height
  const { width, height } = productPage

  yield put(
    productPages.actions.updateOne({
      id: productPageId,
      changes: getOrientationDimensions(width, height, orientation),
    })
  )

  yield all(
    productPage.imageRegionIds.map(imageRegionId =>
      call(switchImageRegionOrientation, { imageRegionId, orientation })
    )
  )
}

function* switchImageRegionOrientation(payload: {
  imageRegionId: EntityId
  orientation: OrientationType
}): SagaIterator {
  const { imageRegionId, orientation } = payload
  const imageRegion: ImageRegionEntity = yield call(
    getImageRegion,
    imageRegionId
  )

  const { window } = imageRegion
  const { width, height } = window

  yield put(
    imageRegions.actions.updateOne({
      id: imageRegionId,
      changes: {
        window: {
          ...window,
          ...getOrientationDimensions(width, height, orientation),
        },
      },
    })
  )

  const { uploadId } = imageRegion
  if (imageRegion.uploadId) {
    yield call(switchUploadOrientation, { imageRegionId, uploadId })
  }
}

export function* switchUploadOrientation(payload: {
  imageRegionId: EntityId
  uploadId: EntityId
}): SagaIterator {
  const { imageRegionId, uploadId } = payload
  const upload: UploadEntity = yield call(getUpload, uploadId)

  const { imageId } = upload
  if (imageId) {
    const imageRegion: ImageRegionEntity = yield call(
      getImageRegion,
      imageRegionId
    )
    const image: ImageEntity = yield call(getImage, imageId)
    const { imageTransformId } = image

    const newImageTransformation = getDefaultImageTransformation(
      {
        width: imageRegion.window.width,
        height: imageRegion.window.height,
      },
      {
        width: image.width,
        height: image.height,
      }
    )

    const { x, y, zoom } = newImageTransformation

    yield put(
      imageTransformations.actions.updateOne({
        id: imageTransformId,
        changes: {
          translation: { x, y },
          zoom,
          minZoom: zoom,
          rotation: 0,
          dirty: false,
        },
      })
    )
  }
}

function* getSupportedOrientationForProduct(payload: {
  productId: EntityId
}): SagaIterator<OrientationType[]> {
  let supportedOrienationTypes: OrientationType[] = ["landscape", "portrait"]
  const { productId } = payload
  const product: PrintServiceProductEntity = yield select((state: RootState) =>
    printServiceProductsSelector.selectById(state, productId)
  )

  if (product.metaData?.orientationOverride) {
    return [product.metaData?.orientationOverride]
  }

  const allProductImages: PrintServiceProductImageEntity[] = []

  if (product.printServiceProductImages.length > 0) {
    for (let i = 0; i < product.printServiceProductImages.length; i++) {
      const productImage: PrintServiceProductImageEntity = yield select(
        (state: RootState) =>
          printServiceProductImagesSelector.selectById(
            state,
            product.printServiceProductImages[i]
          )
      )

      // get only frames and skip preview images
      // eg canvas has product image used for preview only
      if (productImage?.metaData?.frame !== undefined) {
        allProductImages.push(productImage)
      }
    }
  }

  if (allProductImages.length > 0) {
    supportedOrienationTypes = allProductImages.map(image => {
      if (image.metaData.frame!.width > image.metaData.frame!.height) {
        return "landscape"
      } else {
        return "portrait"
      }
    })

    supportedOrienationTypes = uniq(supportedOrienationTypes)
  }

  return supportedOrienationTypes
}

export function* autoDetectOrientationForLineItem(payload: {
  lineItemId: EntityId
  imageId: EntityId
}) {
  const { lineItemId, imageId } = payload
  const lineItem: LineItemEntity = yield call(getLineItem, lineItemId)
  const { productId } = lineItem
  const image: ImageEntity = yield call(getImage, imageId)
  const { width, height } = image
  let orientation: OrientationType = width > height ? "landscape" : "portrait"

  const supportedOrientationTypes: OrientationType[] = yield call(
    getSupportedOrientationForProduct,
    { productId }
  )

  // if supportedOrientationTypes is greater than 1
  // then we can considere that we support any orientation
  // we just keep the autodetection running
  if (supportedOrientationTypes.length === 1) {
    orientation = supportedOrientationTypes[0]
  }

  if (lineItem.orientation !== orientation) {
    yield call(
      processUpdateLineItemOrientation,
      actions.updateLineItemOrientation({
        lineItemId,
        orientation,
      })
    )
  }
}
