import React from "react"
import { QueryClient, QueryClientProvider } from "react-query"
import { Provider } from "react-redux"
import { RecoilRoot } from "recoil"
import BootstrapApplication from "./components/BootstrapApplication"
import { createStore } from "./redux/store"
import { initReactI18next } from "react-i18next"
import i18next from "i18next"

const config = require("../config/config.json")
const preloadedState = require("../config/preloaded-state.json")

const lng = config.language ?? "en-US"

i18next.use(initReactI18next).init({
  debug: process.env.GATSBY_ACTIVE_ENV !== "production",
  lng,
  returnEmptyString: false,
  fallbackLng: "en-US",
  resources: {
    "en-US": {
      translation: require("../languages/en-US/translation.json"),
    },
    [lng]: {
      translation: require(`../languages/${lng}/translation.json`),
    },
  },
})

const store = createStore({ name: config.name, preloadedState })

const queryClient = new QueryClient()

const ApplicationWrapper = ({ element }: { element: any }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <RecoilRoot>
          <BootstrapApplication>{element}</BootstrapApplication>
        </RecoilRoot>
      </Provider>
    </QueryClientProvider>
  )
}

export default ApplicationWrapper
