import { PrintServiceProductEntity } from "@jackfruit/common"
import { SagaIterator } from "@redux-saga/types"
import { PayloadAction } from "@reduxjs/toolkit"
import { flatten } from "lodash"
import { call, put, select, take, takeEvery } from "redux-saga/effects"
import { ImageRegionEntity } from "~/interfaces/entities/ImageRegion"
import { LineItemEntity } from "~/interfaces/entities/LineItem"
import { ProductPageEntity } from "~/interfaces/entities/ProductPage"
import { actions, UpdateMissingImagesForLineItem } from "../process"
import { imageRegionsSelectors } from "../state/imageRegions"
import { productPagesSelectors } from "../state/productPages"
import { printServiceProductsSelector } from "../state/printServiceProducts"
import { RootState } from "../store"
import { getLineItem } from "./lineItems"

export default function* watchUpdateMissingImagesForLineItem() {
  yield takeEvery(
    actions.updateMissingImagesForLineItem.type,
    processUpdateMissingImagesForLineItem
  )
}

function* processUpdateMissingImagesForLineItem(
  action: PayloadAction<UpdateMissingImagesForLineItem>
): SagaIterator<any> {
  const { lineItemId, files, onOpen, onClose } = action.payload

  const lineItem: LineItemEntity = yield call(getLineItem, lineItemId)
  const productPages: ProductPageEntity[] = yield select((state: RootState) =>
    productPagesSelectors.selectByIds(state, lineItem.productPageIds)
  )
  const product: PrintServiceProductEntity = yield select((state: RootState) =>
    printServiceProductsSelector.selectById(state, lineItem.productId)
  )
  const isPhotoBook = product.categoryName === "photo-book"
  const allImageRegionIds = flatten(
    productPages.map(productPage => productPage.imageRegionIds)
  )

  const allImageRegions: ImageRegionEntity[] = yield select(
    (state: RootState) =>
      imageRegionsSelectors.selectByIds(state, allImageRegionIds)
  )

  const imageRegionCount = allImageRegions.length
  const imageRegionWithImageCount = allImageRegions.filter(imageRegion =>
    Boolean(imageRegion.uploadId)
  ).length

  // images has already been applied to line item
  // but some pages are still empty
  const requireConfirmation =
    imageRegionWithImageCount > 0 &&
    imageRegionWithImageCount < imageRegionCount

  let confirm = true
  if (requireConfirmation) {
    onOpen?.()
    const {
      payload: { choice },
    } = yield take(actions.setUpdateMissingImagesDialogChoice.type)
    onClose?.()
    confirm = choice
  }

  // we got the user confirmation, let's apply new images to empty pages
  if (confirm) {
    let currentFileIndex = 0
    for (let i = 0; i < allImageRegions.length; i++) {
      const currentImageRegion = allImageRegions[i]
      const hasUploadAttached = Boolean(currentImageRegion.uploadId)
      if (!hasUploadAttached) {
        const currentFile = files[currentFileIndex]
        yield put(
          actions.updateImageRegionImage({
            imageRegionId: currentImageRegion.id,
            file: currentFile,
            allowPortrait: isPhotoBook,
          })
        )
        currentFileIndex++
        // no more files available, let's break out
        if (currentFileIndex >= files.length) {
          break
        }
      }
    }
  }
}
