import { PrinticularOrder } from "@jackfruit/common"
import { SagaIterator } from "@redux-saga/types"
import { PayloadAction } from "@reduxjs/toolkit"
import { navigate } from "gatsby"
import {
  call,
  delay,
  getContext,
  put,
  select,
  takeLatest,
} from "redux-saga/effects"
import { CartEntity } from "~/interfaces/entities/Cart"
import { OrderSummaryEntity } from "~/interfaces/entities/OrderSummary"
import { PageSessionEntity } from "~/interfaces/entities/PageSession"
import { StoreEntity } from "~/interfaces/entities/Store"
import {
  getExceptionDisplayedMessage,
  isFailedPayment,
} from "~/services/ApiExceptionHelper"
import { Gtm } from "~/services/Gtm"
import { logger } from "~/services/Logger"
import {
  actions as process,
  CreateOrderPayload,
  PlaceOrderPayload,
} from "../process"
import { application } from "../state/application"
import { CartState } from "../state/cart"
import { carts, cartsActions, cartsSelector } from "../state/carts"
import { orders } from "../state/orders"
import {
  orderSummariesActions,
  orderSummariesSelector,
} from "../state/orderSummaries"
import {
  pageSessionsActions,
  pageSessionsSelector,
} from "../state/pageSessions"
import { storesSelectors } from "../state/stores"
import { RootState } from "../store"
import {
  selectOrderData,
  sendOrderToPrinticular,
  validateOrder,
} from "./helpers"
import { AnalyticAction, processAnalyticEvents } from "./processAnalyticEvents"
import { saveCheckoutDataToLocalStorage } from "./processRestoreDataFromLocalStorage"
import { v4 as uuidv4 } from "uuid"

export function* watchProcessPlaceOrder() {
  yield takeLatest(process.placeOrder.type, processPlaceOrder)
}

function* processPlaceOrder(
  action: PayloadAction<PlaceOrderPayload>
): SagaIterator<any> {
  const orderData = yield call(selectOrderData)
  const { isValid: isOrderValid, errorMessage } = validateOrder(orderData)

  // GET CURRENT PAGE
  const getCurrentPageId = yield getContext("getCurrentPageId")
  const pageId = getCurrentPageId()

  const pageSession: PageSessionEntity = yield select((state: RootState) =>
    pageSessionsSelector.selectById(state, pageId)
  )
  const { cartId } = pageSession
  const cart: CartState = yield select((state: RootState) => state.cart)
  const pageCart: CartEntity = yield select((state: RootState) =>
    cartsSelector.selectById(state, cartId)
  )
  const store: StoreEntity = yield select((state: RootState) =>
    storesSelectors.selectById(state, pageCart.storeId)
  )

  const { orderSummaryId } = pageSession
  const { fulfillment } = pageCart

  const storeInOrder = fulfillment === "pickup" ? store : null

  if (!isOrderValid) {
    logger.log("Order is not complete enough to be processed", errorMessage)
  } else {
    logger.log("Placing order")
    try {
      yield put(orderSummariesActions.setIsProcessing(orderSummaryId, true))

      // place order
      const printicularOrder: PrinticularOrder = yield call(
        sendOrderToPrinticular,
        "placeOrder"
      )

      // generate order
      const orderId = printicularOrder.id
      yield put(
        orders.actions.addOne({
          id: orderId,
          orderFromPageId: pageId,
          data: printicularOrder,
          cart: {
            ...cart,
            ...pageCart,
          },
          store: storeInOrder,
          isComplete: true,
        })
      )

      Gtm.firePurchaseEvent(printicularOrder, storeInOrder)
      Gtm.fireSubmitOrderEvent(printicularOrder, storeInOrder)

      yield call(
        processAnalyticEvents,
        AnalyticAction({
          eventType: "purchase",
          data: { order: printicularOrder, store: storeInOrder },
        })
      )

      // save success checkout to local storage for future order
      yield call(saveCheckoutDataToLocalStorage)
      // set order id to the application state for the final page
      yield put(application.actions.setLastOrderId({ orderId }))

      // redirect to success page
      yield call(navigate as any, `/${action.payload.successUrl}`)
      // allow time to navigate to final page
      // this delay prevent the page from flickering before redirect happend
      yield delay(1000)

      // flag page session for reset
      yield put(pageSessionsActions.setIsReady(pageSession.id, false))
    } catch (error: any) {
      const errorMessage = getExceptionDisplayedMessage(error)

      // Get order summary information when order fails
      const orderSummary: OrderSummaryEntity = yield select(
        (state: RootState) =>
          orderSummariesSelector.selectById(state, orderSummaryId)
      )
      const { totalFloat: orderValue } = orderSummary.data

      Gtm.fireSubmitOrderFailedEvent(errorMessage, storeInOrder, orderValue)
      if (isFailedPayment(error)) {
        logger.error(`payment failure: ${errorMessage}`)

        //renew UUID if there is a payment failure
        //to prevent duplicated-nonce error
        yield put(
          carts.actions.updateOne({
            id: pageId,
            changes: { nonce: uuidv4() },
          })
        )

        Gtm.firePaymentFailedEvent(errorMessage, storeInOrder, orderValue)
      } else {
        logger.error(error)
      }

      yield put(orderSummariesActions.setProcessingError(orderSummaryId, error))
    } finally {
      yield put(orderSummariesActions.setIsProcessing(orderSummaryId, false))
    }
  }
}

export function* watchProcessCreateOrder() {
  yield takeLatest(process.createOrder.type, processCreateOrder)
}

function* processCreateOrder(
  action: PayloadAction<CreateOrderPayload>
): SagaIterator<any> {
  const orderData = yield call(selectOrderData)
  const { isValid: isOrderValid, errorMessage } = validateOrder(orderData)

  // GET CURRENT PAGE
  const getCurrentPageId = yield getContext("getCurrentPageId")
  const pageId = getCurrentPageId()

  const pageSession: PageSessionEntity = yield select((state: RootState) =>
    pageSessionsSelector.selectById(state, pageId)
  )
  const { cartId, orderSummaryId } = pageSession
  const cart: CartState = yield select((state: RootState) => state.cart)
  const pageCart: CartEntity = yield select((state: RootState) =>
    cartsSelector.selectById(state, cartId)
  )

  if (!isOrderValid) {
    logger.log("Order is not complete enough to be processed", errorMessage)
  } else {
    logger.log("Creating order")
    try {
      yield put(orderSummariesActions.setIsProcessing(orderSummaryId, true))
      // create order
      const printicularOrder: PrinticularOrder = yield call(
        sendOrderToPrinticular,
        "createOrder"
      )

      // generate order details
      const orderId = printicularOrder.id
      yield put(
        orders.actions.addOne({
          id: orderId,
          orderFromPageId: pageId,
          data: printicularOrder,
          cart: {
            ...cart,
            ...pageCart,
          },
          isComplete: true,
        })
      )

      if (printicularOrder.paymentIntent) {
        yield put(
          cartsActions.setPaymentIntent(cartId, printicularOrder.paymentIntent)
        )
      }

      // save success checkout to local storage for future order
      yield call(saveCheckoutDataToLocalStorage)
      // set order id to the application state for the final page
      yield put(application.actions.setLastOrderId({ orderId }))

      //If there is a duplicated order and no paymentIntent, redirect the user
      //to the Thank You page
      if (orderId === 1 && !pageCart.paymentIntent) {
        yield call(navigate as any, `/${action.payload.successUrl}`)
        yield delay(1000)
        yield put(pageSessionsActions.setIsReady(pageSession.id, false))
      }
    } catch (error: any) {
      const errorMessage = getExceptionDisplayedMessage(error)

      // Get order summary information when order fails
      const orderSummary: OrderSummaryEntity = yield select(
        (state: RootState) =>
          orderSummariesSelector.selectById(state, orderSummaryId)
      )
      const { totalFloat: orderValue } = orderSummary.data

      Gtm.fireSubmitOrderFailedEvent(errorMessage, null, orderValue)

      logger.error(error)
      console.log(errorMessage)

      yield put(orderSummariesActions.setProcessingError(orderSummaryId, error))
    } finally {
      yield put(orderSummariesActions.setIsProcessing(orderSummaryId, false))
    }
  }
}
