import {
  EntityId,
  PageEntity,
  PrintServiceProductEntity,
  PrintServiceProductImageEntity,
} from "@jackfruit/common"
import { NavigateFn } from "@reach/router"
import {
  call,
  delay,
  getContext,
  put,
  take,
  takeEvery,
} from "@redux-saga/core/effects"
import { SagaIterator } from "@redux-saga/types"
import { nanoid, PayloadAction } from "@reduxjs/toolkit"
import { LineItem } from "~/interfaces/entities/autopilot/AccountWithOrders"
import { CartEntity } from "~/interfaces/entities/Cart"
import { ImageEntity } from "~/interfaces/entities/Image"
import { ImageRegionEntity } from "~/interfaces/entities/ImageRegion"
import { ImageTransformationEntity } from "~/interfaces/entities/ImageTransformation"
import { LineItemEntity } from "~/interfaces/entities/LineItem"
import { PageSessionEntity } from "~/interfaces/entities/PageSession"
import { ProductPageEntity } from "~/interfaces/entities/ProductPage"
import { UploadEntity } from "~/interfaces/entities/Upload"
import { logger } from "~/services/Logger"
import { getMostCompatibleProduct } from "~/services/Utils"
import {
  actions,
  PlaceReOrderPayload,
  ProductCompatibilityDialogPayload,
} from "../process"
import { imageRegions } from "../state/imageRegions"
import { images } from "../state/images"
import { imageTransformations } from "../state/imageTransformations"
import { lineItems } from "../state/lineItems"
import { productPages } from "../state/productPages"
import { uploads } from "../state/uploads"
import { getCart } from "./cart"
import { getNextActionableBlock } from "./helpers"
import { getPage } from "./page"
import { getPageSession } from "./pageSession"
import { getPrintServiceProductImages } from "./printServiceProductImages"
import { getAllPrintServiceProducts } from "./printServiceProducts"
import { bootstrapPage } from "./processBootstrapPage"

export function* watchPlaceReOrder() {
  yield takeEvery(actions.placeReOrder.type, processPlaceReOrder)
}

function* processPlaceReOrder(action: PayloadAction<PlaceReOrderPayload>) {
  const { order, onOpen, onClose } = action.payload
  const { pageId } = order.metaData.web
  const navigate: NavigateFn = yield getContext("navigate")
  const scrollTo: (to: string) => void = yield getContext("scrollTo")

  yield call(bootstrapPage, actions.bootstrapPage({ id: pageId }))

  const page: PageEntity = yield call(getPage, { pageId })
  const pageSession: PageSessionEntity = yield call(getPageSession, {
    pageSessionId: page.id,
  })
  const cart: CartEntity = yield call(getCart, pageSession.cartId)
  const nextBlockName: string = yield call(getNextActionableBlock, {
    pageId,
    currentBlock: "image-upload",
  })

  // first check if all line item can be replaced
  const lineItemsWithNoProducts: LineItem[] = []
  for (const lineItem of order.lineItems) {
    try {
      yield call(findProductFromRemoteLineItem, lineItem)
    } catch (error) {
      lineItemsWithNoProducts.push(lineItem)
    }
  }

  const canReOrderSomeProducts =
    order.lineItems.length > lineItemsWithNoProducts.length
  const lineItemLabels = lineItemsWithNoProducts.map(
    lineItem => lineItem.product[0].shortDescription
  )

  let confirm = true
  if (lineItemsWithNoProducts.length > 0) {
    onOpen?.({ canReOrderSomeProducts, lineItemLabels })
    const {
      payload: { choice },
    }: PayloadAction<ProductCompatibilityDialogPayload> = yield take(
      actions.productCompatibilityDialog.type
    )
    onClose?.()
    confirm = choice
  }

  if (confirm) {
    for (const lineItem of order.lineItems) {
      try {
        const lineItemId: EntityId = yield call(reCreateLineItem, {
          lineItem,
        })

        yield put(actions.addLineItemToCart({ lineItemId, cartId: cart.id }))
      } catch (error) {
        logger.error(error)
      }
    }

    navigate(page.path!)
    yield delay(1000)
    scrollTo(nextBlockName)
  }
}

function* reCreateLineItem(payload: { lineItem: LineItem }) {
  const { lineItem } = payload

  const { addOne: addOneImageTransform } = imageTransformations.actions
  const { addOne: addOneImage } = images.actions
  const { addOne: addOneUpload } = uploads.actions
  const { addOne: addOneImageRegion } = imageRegions.actions
  const { addOne: addOneProductPage } = productPages.actions
  const { addOne: addOneLineItem } = lineItems.actions

  const matchingProduct: PrintServiceProductEntity = yield call(
    findProductFromRemoteLineItem,
    lineItem
  )

  const firstProcessedImage = lineItem.processedImage[0]

  const orientation =
    firstProcessedImage.width > firstProcessedImage.height
      ? "landscape"
      : "portrait"

  const productPageIds: EntityId[] = []
  const renderedUploadIds: EntityId[] = []

  for (const lineItemImage of lineItem.processedImage) {
    // create one page per image
    const imageTransformId = nanoid()
    const imageId = nanoid()
    const uploadId = nanoid()
    const imageRegionId = nanoid()
    const productPageId = nanoid()

    const imageTransformData: Partial<ImageTransformationEntity> = {
      id: imageTransformId,
      minZoom: 1,
      zoom: 1,
      rotation: 0,
      translation: {
        x: 0,
        y: 0,
      },
    }

    const imageData: Partial<ImageEntity> = {
      id: imageId,
      imageTransformId,
      width: lineItemImage.width,
      height: lineItemImage.height,
      localUrl: lineItemImage.fullUrl,
      externalUrl: lineItemImage.fullUrl,
      mimeType: lineItemImage.mimeType,
    }

    const uploadData: Partial<UploadEntity> = {
      id: uploadId,
      imageId,
      name: lineItemImage.filename,
      type: lineItemImage.mimeType,
      size: lineItemImage.bytesize,
      status: "ready",
      hash: lineItemImage.checksum,
      progress: 100,
      hasFailed: false,
      error: null,
      location: lineItemImage.fullUrl,
    }

    const imageRegionData: Partial<ImageRegionEntity> = {
      id: imageRegionId,
      uploadId,
      window: {
        x: 0,
        y: 0,
        width: lineItemImage.width,
        height: lineItemImage.height,
      },
    }

    const productPageData: Partial<ProductPageEntity> = {
      id: productPageId,
      imageRegionIds: [imageRegionId],
      textRegionIds: [], // todo: confirm that text regions aren't editable on a duplicated line item
      width: lineItemImage.width,
      height: lineItemImage.height,
    }

    yield put(addOneImageTransform(imageTransformData))
    yield put(addOneImage(imageData))
    yield put(addOneImageRegion(imageRegionData))
    yield put(addOneUpload(uploadData))
    yield put(addOneProductPage(productPageData))
    productPageIds.push(productPageId)
    renderedUploadIds.push(uploadId)
  }

  // create line item
  const lineItemId = nanoid() as EntityId
  const lineItemData: Partial<LineItemEntity> = {
    id: lineItemId,
    productPageIds,
    productId: matchingProduct?.id,
    isConfirmed: true,
    isEditing: false,
    isReady: true,
    isTemplate: false,
    isReadOnly: true,
    orientation,
    quantity: lineItem.quantity,
    renderedUploadIds,
  }

  yield put(addOneLineItem(lineItemData))

  return lineItemId
}

function* findProductFromRemoteLineItem(
  lineItem: LineItem
): SagaIterator<PrintServiceProductEntity> {
  const lineItemProduct = lineItem.product[0]
  const allProducts: PrintServiceProductEntity[] = yield call(
    getAllPrintServiceProducts
  )

  let matchingProduct = allProducts.find(
    p => p.remoteId === Number(lineItemProduct.id)
  )

  const firstProcessedImage = lineItem.processedImage[0]

  const orientation =
    firstProcessedImage.width > firstProcessedImage.height
      ? "landscape"
      : "portrait"

  const foundOrientation =
    matchingProduct?.metaData?.orientationOverride ?? orientation

  if (!matchingProduct || orientation !== foundOrientation) {
    throw Error(
      `Unable to find matching product for re ordering, product: ${lineItemProduct.id}`
    )
  }

  // get product image to check if the product is an inner frame type
  const productImages: PrintServiceProductImageEntity[] = yield call(
    getPrintServiceProductImages,
    matchingProduct!.id
  )

  if (productImages.length > 0) {
    const isInnerFrame = productImages[0].metaData.frame?.layoutType === "inner"
    if (isInnerFrame) {
      // select a print product with similar attributes
      const remainingProducts = allProducts.filter(
        product => product.id !== matchingProduct!.id
      )
      const newMatchingProduct = getMostCompatibleProduct(
        matchingProduct!,
        remainingProducts
      )
      if (!newMatchingProduct) {
        throw Error(
          `Unable to find matching product for re ordering, product: ${lineItemProduct.id}`
        )
      }

      matchingProduct = newMatchingProduct
    }
  }

  return matchingProduct
}
