import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Link,
  SkeletonCircle,
  SkeletonText,
  Spacer,
} from "@chakra-ui/react"
import {
  AppEntityHydrated,
  BlockHeaderEntity,
  PageEntityHydrated,
} from "@jackfruit/common"
import React, { useMemo } from "react"
import { useAuth } from "~/hooks/useAuth"
import { useNextActionableBlock } from "~/hooks/useNextActionableBlock"
import { useSettings } from "~/hooks/useSettings"
import Navigation from "../navigation/Navigation"
import ScrollLinkWrapper from "../scroll-wrappers/ScrollLinkWrapper"
import Wrapper from "../Wrapper"
import MyAccountMenu from "./MyAccount/MyAccountMenu"
import SignInMenu from "./SignIn/SignInMenu"

interface Props {
  config: BlockHeaderEntity
  pages: PageEntityHydrated[]
  appConfig: AppEntityHydrated
}

const HeaderDesktop: React.FC<Props> = ({ config, pages, appConfig }) => {
  const { displayName } = appConfig
  const {
    topNavigationId,
    topNavigationBackgroundColor,
    topNavigationColor,
    brandNavigationId,
    brandNavigationBackgroundColor,
    brandNavigationColor,
    mainNavigationId,
    mainNavigationBackgroundColor, //"#edf2f6",
    mainNavigationColor = "#313e57",
    callToActionText,
  } = config

  const { topNavigation, brandNavigation, mainNavigation } = useMemo(() => {
    const topNavigation = appConfig.navigations.find(
      nav => Number(nav.id) === Number(topNavigationId)
    )
    const brandNavigation = appConfig.navigations.find(
      nav => Number(nav.id) === Number(brandNavigationId)
    )
    const mainNavigation = appConfig.navigations.find(
      nav => Number(nav.id) === Number(mainNavigationId)
    )

    return { topNavigation, brandNavigation, mainNavigation }
  }, [
    appConfig.navigations,
    brandNavigationId,
    mainNavigationId,
    topNavigationId,
  ])

  const { baseUrl, logoImage } = appConfig
  const { pathname: basePathname } = new URL(baseUrl)

  const { nextBlockType, hasMinimumActionableBlockRequirement } =
    useNextActionableBlock("header")
  const shouldShowGetStarted =
    hasMinimumActionableBlockRequirement && nextBlockType

  const {
    settings: { isUserAccountEnabled },
  } = useSettings()

  const { state: authState } = useAuth()
  const logoComponent =
    logoImage && logoImage.path ? (
      <Image
        id="p-header-brand-logo"
        width="auto"
        height="auto"
        htmlHeight={54}
        htmlWidth={150}
        maxH="4rem"
        ignoreFallback={true}
        src={logoImage.path}
        alt={`${displayName} Logo`}
        title={`${displayName} Logo`}
      />
    ) : (
      <Heading as="h1" fontSize="xl">
        Autopilot Print
      </Heading>
    )

  const getStartedButton = (
    <Button
      colorScheme="primary"
      color="primary.text"
      id="p-nav-get-started" // GTM: Get Started Nav Link Click
    >
      {callToActionText}
    </Button>
  )

  return (
    <Box>
      {topNavigation && (
        <Wrapper
          borderBottomWidth={1}
          borderBottomColor="gray.100"
          backgroundColor={topNavigationBackgroundColor}
        >
          <Flex justifyContent="flex-end">
            <Box mr={-2}>
              <Navigation
                justify="flex-end"
                fontSize="xs"
                color={topNavigationColor}
                navigation={topNavigation}
                pages={pages}
                px={2}
                py={2}
              />
            </Box>

            {!!isUserAccountEnabled && (
              <Flex ml={4} alignItems="center">
                {authState.isLoading ? (
                  <Flex alignItems="center" position="relative">
                    <SkeletonCircle size="5" />
                    <SkeletonText noOfLines={1} width={20} ml={2} />
                  </Flex>
                ) : (
                  <>
                    {!authState.isAuthenticated ? (
                      <SignInMenu color={topNavigationColor} />
                    ) : (
                      <MyAccountMenu color={topNavigationColor} />
                    )}
                  </>
                )}
              </Flex>
            )}
          </Flex>
        </Wrapper>
      )}
      <Wrapper
        boxShadow="sm"
        position="relative"
        zIndex="docked"
        bg={brandNavigationBackgroundColor ?? "white"}
      >
        <Flex
          justifyContent={brandNavigation ? "right" : "space-between"}
          alignItems="center"
          py={2}
        >
          <Link id="p-header-logo-link" href={basePathname ?? "/"}>
            {logoComponent}
          </Link>
          {brandNavigation && (
            <>
              <Spacer />
              <Wrapper zIndex={20} position="relative">
                <Navigation
                  color={brandNavigationColor}
                  colorHover="primary.600"
                  navigation={brandNavigation}
                  pages={pages}
                  justify="right"
                  py={2}
                />
              </Wrapper>
            </>
          )}
          {shouldShowGetStarted && (
            <ScrollLinkWrapper
              toType="nextAction"
              currentBlock="header"
              offset={0}
            >
              {getStartedButton}
            </ScrollLinkWrapper>
          )}
        </Flex>
      </Wrapper>

      {mainNavigation && (
        <Box
          backgroundColor={mainNavigationBackgroundColor ?? "#f8f9fa"}
          boxShadow="base"
          zIndex={10}
          position="relative"
          paddingX={{
            base: "4",
            md: "10",
          }}
        >
          <Navigation
            justify="center"
            color={mainNavigationColor ?? "#313e57"}
            colorHover="primary.600"
            navigation={mainNavigation}
            pages={pages}
            py={4}
            spacing={8}
          />
        </Box>
      )}
    </Box>
  )
}

export default HeaderDesktop
