import {
  EntityId,
  OrientationType,
  PrintServiceProductEntity,
  PrintServiceProductEntityHydrated,
} from "@jackfruit/common"
import { SagaIterator } from "@redux-saga/types"
import { PayloadAction } from "@reduxjs/toolkit"
import { all, call, put, select, takeEvery } from "redux-saga/effects"
import { CartEntity } from "~/interfaces/entities/Cart"
import { ImageEntity } from "~/interfaces/entities/Image"
import { ImageRegionEntity } from "~/interfaces/entities/ImageRegion"
import { LineItemEntity } from "~/interfaces/entities/LineItem"
import { PageSessionEntity } from "~/interfaces/entities/PageSession"
import { ProductPageEntityHydrated } from "~/interfaces/entities/ProductPage"
import { UploadEntity } from "~/interfaces/entities/Upload"
import { getPagesFromProduct } from "~/services/PrintServiceProductHelpers"
import { getDefaultImageTransformation, getExcessArray } from "~/services/Utils"
import { actions, UpdateLineItemProduct } from "../process"
import { imageRegions, imageRegionsSelectors } from "../state/imageRegions"
import { imagesSelectors } from "../state/images"
import { imageTransformations } from "../state/imageTransformations"
import { lineItems, lineItemsSelectors } from "../state/lineItems"
import { printServiceProductImagesSelector } from "../state/printServiceProductImages"
import { printServiceProductsSelector } from "../state/printServiceProducts"
import {
  productPages,
  productPages as productPagesSlice,
  productPagesSelectors,
} from "../state/productPages"
import { uploadsSelectors } from "../state/uploads"
import { RootState } from "../store"
import { getCurrentCart } from "./cart"
import { canSupportMultipleOrientations } from "./helpers"
import { getCurrentPageSession } from "./pageSession"
import { AnalyticAction, processAnalyticEvents } from "./processAnalyticEvents"
import { switchPageOrientation } from "./processUpdateLineItemOrientation"
import { getProductTemplateVariantForProduct } from "./productTemplateVariants"
import { updateProductPages } from "./templateHelpers"

export default function* watchUpdateLineItemProduct() {
  yield takeEvery(
    actions.updateLineItemProduct.type,
    processUpdateLineItemProduct
  )
}

export function* processUpdateLineItemProduct(
  action: PayloadAction<UpdateLineItemProduct>
): SagaIterator {
  // FIXEME why is the product id comming as a string here?
  const { lineItemId, productId: newNonCastedProductId, event } = action.payload
  const newProductId = +newNonCastedProductId

  const pageSession: PageSessionEntity = yield call(getCurrentPageSession)
  const cart: CartEntity = yield call(getCurrentCart)

  const lineItem: LineItemEntity = yield select((state: RootState) =>
    lineItemsSelectors.selectById(state, lineItemId)
  )

  const newProduct: PrintServiceProductEntity = yield select(
    (state: RootState) =>
      printServiceProductsSelector.selectById(state, newProductId)
  )
  const isNewProductPhotoBook = newProduct.categoryName === "photo-book"
  // track previous product id for analytic
  const oldProductId = lineItem.productId

  if (!lineItem.isTemplate) {
    const { productPageIds, orientation } = lineItem

    const printServiceProductImages = yield select((state: RootState) =>
      printServiceProductImagesSelector.selectByIds(
        state,
        newProduct.printServiceProductImages || []
      )
    )

    const denormalizedProduct: PrintServiceProductEntityHydrated = {
      ...newProduct,
      printServiceProductImages,
    }

    const newProductPages = getPagesFromProduct(denormalizedProduct)

    const editedProductPages: ProductPageEntityHydrated[] = yield all(
      newProductPages.map((newProductPage, index) =>
        call(processProductPage, {
          newProductPage,
          productPageId: productPageIds?.[index],
          allowPortrait: isNewProductPhotoBook,
        })
      )
    )

    const editedProductPagesIds = editedProductPages.map(e => e.id)

    yield put(
      lineItems.actions.updateOne({
        id: lineItemId,
        changes: {
          productId: newProductId,
          productPageIds: editedProductPagesIds,
        },
      })
    )

    // Remove unused productPageIds
    const productPageIdsToRemove = getExcessArray(
      productPageIds,
      editedProductPagesIds
    )

    yield put(productPages.actions.removeMany(productPageIdsToRemove))

    const supportsMultipleOrientations = yield call(
      canSupportMultipleOrientations,
      lineItemId
    )

    if (!supportsMultipleOrientations) {
      const defaultOrientation: OrientationType =
        newProduct.pixelHeight > newProduct.pixelWidth
          ? "portrait"
          : "landscape"

      const newOrientation =
        newProduct.metaData?.orientationOverride ?? defaultOrientation

      yield put(
        actions.updateLineItemOrientation({
          lineItemId,
          orientation: newOrientation,
        })
      )
    } else {
      // Preserve existing lineItem orientation if possible
      yield all(
        editedProductPagesIds.map(productPageId =>
          call(switchPageOrientation, {
            productPageId,
            orientation,
          })
        )
      )
    }
  } else {
    // Templates
    const productTemplateId = lineItem.productTemplateId
    const variants = yield call(getProductTemplateVariantForProduct, {
      productId: newProductId,
      productTemplateId: productTemplateId!,
    })
    const firstVariant = variants[0]
    const firstProductPage = firstVariant.variant.pages[0]

    const defaultOrientation =
      firstProductPage.width > firstProductPage.height
        ? "landscape"
        : "portrait"

    const orientation =
      newProduct.metaData?.orientationOverride ?? defaultOrientation

    // update product pages based on the variant and the new selected product
    yield call(updateProductPages, {
      productPageIds: lineItem.productPageIds,
      variant: firstVariant.variant,
    })

    yield put(
      lineItems.actions.updateOne({
        id: lineItemId,
        changes: {
          orientation,
          productId: +newProductId,
        },
      })
    )
  }

  yield call(
    processAnalyticEvents,
    AnalyticAction({
      eventType: "replaceProductInCart",
      data: {
        lineItemId,
        oldProductId,
        oldQuantity: lineItem.quantity,
        newProductId,
        newQuantity: lineItem.quantity,
      },
    })
  )

  const hasAStoreSelected = cart.storeId
  if (
    pageSession.pageFlow === "product-first" &&
    cart.fulfillment === "pickup" &&
    event === "productChanged" &&
    hasAStoreSelected
  ) {
    yield put(actions.searchStores({}))
  }

  if (event !== "fulfillmentChanged") {
    yield put(
      actions.updateOrderSummary({
        reason: "updated lineItem product",
        reasonType: "cartChange",
      })
    )
  }
}

/**
 * Product Page process
 * --------------------------
 */
function* processProductPage(payload: {
  newProductPage: ProductPageEntityHydrated
  productPageId?: EntityId
  allowPortrait: boolean
}): SagaIterator<ProductPageEntityHydrated> {
  const { newProductPage, productPageId, allowPortrait } = payload
  const { imageRegions: newImageRegions } = newProductPage

  const productPageEntity = yield select((state: RootState) =>
    productPagesSelectors.selectById(state, productPageId!)
  )

  const editedImageRegions: ImageRegionEntity[] = yield all(
    newImageRegions.map((newImageRegion, index) =>
      call(processImageRegion, {
        newImageRegion,
        imageRegionId: productPageEntity?.imageRegionIds?.[index],
        allowPortrait,
      })
    )
  )

  const editedImageRegionsIds = editedImageRegions.map(e => e.id)

  const imageRegionPayload = {
    width: newProductPage.width,
    height: newProductPage.height,
    imageRegionIds: editedImageRegionsIds,
    textRegionIds: [],
  }

  // --- Create a new productPage as it didn't exists before ---
  if (!productPageId) {
    yield put(
      productPagesSlice.actions.addOne({
        ...imageRegionPayload,
        id: newProductPage.id,
      })
    )

    return newProductPage
  }

  // --- Update existing productPage ---
  yield put(
    productPagesSlice.actions.updateOne({
      id: productPageId,
      changes: imageRegionPayload,
    })
  )

  // Remove unused imageRegionsIds
  const imageRegionsIdsToRemove = getExcessArray(
    productPageEntity.imageRegionIds,
    editedImageRegionsIds
  )

  yield put(imageRegions.actions.removeMany(imageRegionsIdsToRemove))

  return { ...newProductPage, id: productPageId }
}

/**
 * Image region process
 * --------------------------
 */
function* processImageRegion(payload: {
  newImageRegion: ImageRegionEntity
  imageRegionId?: EntityId
  allowPortrait: boolean
}): SagaIterator<ImageRegionEntity> {
  const { newImageRegion, imageRegionId, allowPortrait } = payload

  // --- Create a new imageRegion as it didn't exists before for that page ---
  if (!imageRegionId) {
    yield put(imageRegions.actions.addOne(newImageRegion))
    return newImageRegion
  }

  // --- Update existing imageRegion ---
  const editedImageRegion = { ...newImageRegion, id: imageRegionId }

  const imageRegion: ImageRegionEntity = yield select((state: RootState) =>
    imageRegionsSelectors.selectById(state, imageRegionId)
  )

  yield put(
    imageRegions.actions.updateOne({
      id: imageRegionId,
      changes: {
        window: newImageRegion.window,
      },
    })
  )

  const { uploadId } = imageRegion

  if (!uploadId) {
    return editedImageRegion
  }

  const upload: UploadEntity = yield select((state: RootState) =>
    uploadsSelectors.selectById(state, uploadId)
  )

  const image: ImageEntity = yield select((state: RootState) =>
    imagesSelectors.selectById(state, upload.imageId)
  )

  const { width, height, imageTransformId } = image

  const targetWindow = {
    width: newImageRegion.window.width,
    height: newImageRegion.window.height,
  }

  const imageDimensions = {
    width,
    height,
  }

  const defaultImageTransformation = getDefaultImageTransformation(
    targetWindow,
    imageDimensions,
    0,
    allowPortrait
  )

  yield put(
    imageTransformations.actions.updateOne({
      id: imageTransformId,
      changes: {
        rotation: 0,
        translation: {
          x: defaultImageTransformation.x,
          y: defaultImageTransformation.y,
        },
        zoom: defaultImageTransformation.zoom,
        minZoom: defaultImageTransformation.zoom,
        dirty: false,
      },
    })
  )

  return editedImageRegion
}
