import { replace } from "@wordpress/shortcode"
import React from "react"
import ReactDOM from "react-dom"
import { renderToString } from "react-dom/server"
import CountrySelector from "~/components/short-codes/CountrySelector"
import { ShortCodeParser } from "../replaceShortCodes"
import { isBrowser } from "../Utils"

export const parser: ShortCodeParser = (html: string = "") => {
  return replace("countrySelect", html, args => {
    const {
      id = "1",
      default: defaultValue = "",
      options = "{}",
    } = args.attrs.named
    const parsedOptions = JSON.parse(options)

    const elementId = `country-selector-${id}`

    if (isBrowser) {
      setTimeout(() => {
        const el = document.getElementById(elementId)
        if (el) {
          ReactDOM.render(
            <CountrySelector value={defaultValue} options={parsedOptions} />,
            el
          )
        }
      })
    }

    return renderToString(<div id={elementId}></div>)
  })
}
