import { Button, Center, Grid, Icon, Image, Text } from "@chakra-ui/react"
import { AppEntityHydrated } from "@jackfruit/common"
import React from "react"
import { useTranslation } from "react-i18next"
import { FiX } from "react-icons/fi"

interface Props {
  appConfig: AppEntityHydrated
  onInstall: () => void
  onCloseBanner: () => void
  slideIn: boolean
  transitionTime: number
}

const AppBannerTop: React.FC<Props> = ({
  appConfig,
  onInstall,
  onCloseBanner,
  slideIn,
  transitionTime,
}) => {
  const { faviconImage } = appConfig

  const { t } = useTranslation()

  return (
    <Center
      p={0}
      bg="gray.700"
      id="install-mobile-app-banner"
      overflow="hidden"
      flexWrap="wrap"
    >
      <Grid
        templateColumns="1fr repeat(3, auto) 1fr"
        justifyItems="center"
        marginTop={slideIn ? 0 : "-88px"}
        transition={`${transitionTime}ms all`}
        width="full"
        p={5}
      >
        <Button
          className="p-mobile-banner-dismiss-button" // GTM: click on mobile banner dismiss button
          bg="none"
          color="white"
          onClick={onCloseBanner}
          mr="auto"
          my="auto"
          ml={0}
          p={0}
        >
          <Icon as={FiX} boxSize={6} color="gray.200" />
        </Button>
        <Image
          className="p-mobile-banner-app-icon" // GTM: click on mobile banner app icon
          src={faviconImage.path}
          htmlWidth={56}
          htmlHeight={56}
          bg="white"
          borderRadius="base"
          onClick={onInstall}
          ignoreFallback={true}
          mx={5}
          my="auto"
        />
        <Text color="white" fontSize={14} my="auto">
          {t("components.AppBanner.Top.BannerTitle")}
        </Text>
        <Button
          bg="primary.500"
          color="white"
          onClick={onInstall}
          className="btn btn-primary" // GTM: click on mobile banner store button
          my="auto"
          mx={5}
        >
          {t("components.AppBanner.Top.Install")}
        </Button>
      </Grid>
    </Center>
  )
}

export default AppBannerTop
