import {
  EntityId,
  OrientationType,
  PrintServiceProductEntity,
  PrintServiceProductImageEntity,
} from "@jackfruit/common"
import { call, put, select } from "@redux-saga/core/effects"
import { SagaIterator } from "@redux-saga/types"
import { nanoid } from "@reduxjs/toolkit"
import { ImageEntity } from "~/interfaces/entities/Image"
import { ImageRegionEntity } from "~/interfaces/entities/ImageRegion"
import { LineItemEntity } from "~/interfaces/entities/LineItem"
import { ProductPageEntity } from "~/interfaces/entities/ProductPage"
import { UploadEntity } from "~/interfaces/entities/Upload"
import { getPagesFromProduct } from "~/services/PrintServiceProductHelpers"
import { findDefaultProductForImage } from "~/services/Utils"
import { imageRegions, imageRegionsSelectors } from "../state/imageRegions"
import { lineItems, lineItemsSelectors } from "../state/lineItems"
import { printServiceProductImagesSelector } from "../state/printServiceProductImages"
import { printServiceProductsSelector } from "../state/printServiceProducts"
import { productPages, productPagesSelectors } from "../state/productPages"
import { textRegions } from "../state/textRegions"
import { uploadsSelectors } from "../state/uploads"
import { RootState } from "../store"
import { getImage, setImageTransformation } from "./images"
import { getPrintServiceProductImages } from "./printServiceProductImages"
import { getPrintServiceProductPrice } from "./printServiceProductPrices"
import {
  getAvailableProductsForCurrentPage,
  getPrintServiceProduct,
} from "./printServiceProducts"
import { AnalyticAction, processAnalyticEvents } from "./processAnalyticEvents"
import { autoDetectOrientationForLineItem } from "./processUpdateLineItemOrientation"
import { getUpload } from "./uploads"

// ====================================================
// Create line item with no data
// ====================================================
export function* createLineItem() {
  const lineItemId = nanoid()
  const { addOne: addOneLineItem } = lineItems.actions

  yield put(
    addOneLineItem({
      id: lineItemId,
      isReady: false,
    })
  )

  return lineItemId
}

// ====================================================
// Update line item prepared with
// all pages and image regions
// ====================================================
export function* updateLineItem(payload: {
  lineItemId: EntityId
  productId: EntityId
}): SagaIterator<EntityId> {
  const { productId, lineItemId } = payload

  const printServiceProduct: PrintServiceProductEntity = yield select(
    (state: RootState) =>
      printServiceProductsSelector.selectById(state, productId)
  )

  const printServiceProductImages: PrintServiceProductImageEntity[] =
    yield select((state: RootState) =>
      printServiceProductImagesSelector.selectByIds(
        state,
        printServiceProduct.printServiceProductImages
      )
    )

  const { addOne: addOneImageRegion } = imageRegions.actions
  const { addOne: addOneTextRegion } = textRegions.actions
  const { addOne: addOneProductPage } = productPages.actions
  const { updateOne: updateOneLineItem } = lineItems.actions

  const requiredProductPages = getPagesFromProduct({
    ...printServiceProduct,
    printServiceProductImages,
  })

  for (let i = 0; i < requiredProductPages.length; i++) {
    const requiredProductPage = requiredProductPages[i]
    const imageRegionIds = []
    for (let j = 0; j < requiredProductPage.imageRegions.length; j++) {
      const imageRegion = requiredProductPage.imageRegions[j]
      yield put(
        addOneImageRegion({
          id: imageRegion.id,
          window: imageRegion.window,
        })
      )
      imageRegionIds.push(imageRegion.id)
    }

    const textRegionIds = []
    for (let j = 0; j < requiredProductPage.textRegions.length; j++) {
      const textRegion = requiredProductPage.textRegions[j]
      yield put(
        addOneTextRegion({
          id: textRegion.id,
          window: textRegion.window,
          align: textRegion.align,
          color: textRegion.color,
          font: textRegion.font,
          placeholder: textRegion.placeholder,
          size: textRegion.size,
          defaultSize: textRegion.defaultSize,
          text: "",
        })
      )
      textRegionIds.push(textRegion.id)
    }

    yield put(
      addOneProductPage({
        id: requiredProductPage.id,
        width: requiredProductPage.width,
        height: requiredProductPage.height,
        imageRegionIds,
        textRegionIds,
      })
    )
  }

  const productPageIds = requiredProductPages.map(page => page.id)

  const orientation: OrientationType =
    printServiceProduct.width > printServiceProduct.height
      ? "landscape"
      : "portrait"

  // create line item
  yield put(
    updateOneLineItem({
      id: lineItemId,
      changes: {
        productPageIds,
        productId,
        quantity: 1,
        orientation,
        isReadOnly: false,
        renderedUploadIds: [],
        isTemplate: false,
        isConfirmed: true,
        isReady: true,
      },
    })
  )

  yield call(
    processAnalyticEvents,
    AnalyticAction({
      eventType: "addOneItemToCart",
      data: { lineItemId },
    })
  )

  return lineItemId
}

// ====================================================
// initialise a line item from an upload entity
// using the default product
// ====================================================
export function* updateLineItemFromUpload(payload: {
  lineItemId: EntityId
  uploadId: EntityId
}): SagaIterator {
  const { uploadId, lineItemId } = payload
  const upload: UploadEntity = yield call(getUpload, uploadId)
  const image: ImageEntity = yield call(getImage, upload.imageId)

  const currentAvailableProducts: PrintServiceProductEntity[] = yield call(
    getAvailableProductsForCurrentPage
  )

  if (currentAvailableProducts.length === 0) {
    // this should never happend
    throw Error("no compatible product found for current context")
  }

  const bestProductForImage =
    findDefaultProductForImage(image, currentAvailableProducts) ??
    currentAvailableProducts[0] // Taking the first product if no best match

  const productId = bestProductForImage.id
  yield call(updateLineItem, { productId, lineItemId })

  yield call(attachUploadToLineItem, { uploadId, lineItemId })
  yield call(autoDetectOrientationForLineItem, {
    lineItemId,
    imageId: image.id,
  })

  return lineItemId
}

// ====================================================
// attach an upload with it's image to an existing
// line item (first page, first image region)
// ====================================================
export function* attachUploadToLineItem(payload: {
  uploadId: EntityId
  lineItemId: EntityId
}): SagaIterator {
  const { uploadId, lineItemId } = payload
  const { updateOne: updateOneImageRegion } = imageRegions.actions

  const lineItem: LineItemEntity = yield select((state: RootState) =>
    lineItemsSelectors.selectById(state, lineItemId)
  )

  const upload: UploadEntity = yield select((state: RootState) =>
    uploadsSelectors.selectById(state, uploadId)
  )

  const firstProductPage: ProductPageEntity = yield select((state: RootState) =>
    productPagesSelectors.selectById(state, lineItem.productPageIds[0])
  )

  const firstImageRegion: ImageRegionEntity = yield select((state: RootState) =>
    imageRegionsSelectors.selectById(state, firstProductPage.imageRegionIds[0])
  )

  // attach the upload to the image region
  yield put(
    updateOneImageRegion({
      id: firstImageRegion.id,
      changes: {
        uploadId: upload.id,
      },
    })
  )

  // apply default transformations
  yield call(setImageTransformation, {
    imageId: upload.imageId,
    window: firstImageRegion.window,
  })
}

// ====================================================
// select one line item
// ====================================================
export function* getLineItem(
  lineItemId: EntityId
): SagaIterator<LineItemEntity> {
  const lineItem: LineItemEntity = yield select((state: RootState) =>
    lineItemsSelectors.selectById(state, lineItemId)
  )

  return lineItem
}

export function* getLineItemFrame(lineItemId: EntityId): SagaIterator {
  const lineItem: LineItemEntity = yield call(getLineItem, lineItemId)
  const { orientation, productId } = lineItem

  const productImages = yield call(getPrintServiceProductImages, productId)

  let currentProductImage = null
  if (productImages.length === 1) {
    currentProductImage = productImages[0]
  } else {
    currentProductImage = productImages.find(
      (productImage: PrintServiceProductImageEntity) => {
        if (orientation === "landscape") {
          return (
            productImage.metaData?.frame?.width! >
            productImage.metaData?.frame?.height!
          )
        } else {
          return (
            productImage.metaData?.frame?.width! <=
            productImage.metaData?.frame?.height!
          )
        }
      }
    )
  }

  return {
    hasOverlay:
      currentProductImage?.metaData?.frame !== undefined &&
      currentProductImage?.metaData.frame?.layoutType === "inner",
    hasFrame:
      currentProductImage?.metaData?.frame !== undefined &&
      currentProductImage?.metaData.frame?.layoutType !== "inner",
    frame: currentProductImage,
    orientation,
    productImages,
  }
}

export function* getLineItemForAnalytic(lineItemId: EntityId): SagaIterator {
  const lineItem: LineItemEntity = yield call(getLineItem, lineItemId)
  const product: PrintServiceProductEntity = yield call(
    getPrintServiceProduct,
    lineItem.productId
  )

  const priceId: EntityId = product.prices[0]
  const price = yield call(getPrintServiceProductPrice, priceId)

  return {
    lineItem,
    product,
    price,
  }
}
