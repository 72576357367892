import {
  EntityId,
  PrintServiceProductEntity,
  PrintServiceProductImageEntity,
} from "@jackfruit/common"
import { SagaIterator } from "@redux-saga/types"
import { nanoid, PayloadAction } from "@reduxjs/toolkit"
import {
  actionChannel,
  call,
  getContext,
  put,
  select,
  take,
  takeEvery,
} from "redux-saga/effects"
import { CartEntity } from "~/interfaces/entities/Cart"
import { LineItemEntity } from "~/interfaces/entities/LineItem"
import { PageSessionEntity } from "~/interfaces/entities/PageSession"
import { ProductPageEntityHydrated } from "~/interfaces/entities/ProductPage"
import { getPagesFromProduct } from "~/services/PrintServiceProductHelpers"
import {
  actions,
  CreateLineItemFromTemplatePayload,
  CreateLineItemPayload,
  InsertPageSpreadToLineItemPayload,
  RemoveLineItemPayload,
  RemovePageSpreadFromLineItemPayload,
} from "../process"
import { carts, cartsSelector } from "../state/carts"
import { giftCertificates } from "../state/giftCertificates"
import { imageRegions } from "../state/imageRegions"
import { lineItems, lineItemsSelectors } from "../state/lineItems"
import { pageSessions } from "../state/pageSessions"
import { printServiceProductImagesSelector } from "../state/printServiceProductImages"
import { printServiceProductsSelector } from "../state/printServiceProducts"
import { productPages } from "../state/productPages"
import { textRegions } from "../state/textRegions"
import { uploads } from "../state/uploads"
import { RootState } from "../store"
import { addLineItemToCart, getCurrentCart } from "./cart"
import { getLineItem } from "./lineItems"
import { getCurrentPageSession } from "./pageSession"
import { AnalyticAction, processAnalyticEvents } from "./processAnalyticEvents"
import { getProductTemplateVariantForProduct } from "./productTemplateVariants"
import { prepareProductPagesForVariant } from "./templateHelpers"

// ====================================================
// Create one line item with default configuration
// from selected product
// ====================================================
export function* watchCreateLineItem() {
  yield takeEvery(actions.createLineItem.type, processCreateLineItem)
}

export function* processCreateLineItem(
  action: PayloadAction<CreateLineItemPayload>
): SagaIterator<any> {
  const { productId } = action.payload

  const getCurrentPageId = yield getContext("getCurrentPageId")
  const currentPageId = getCurrentPageId()

  const cart: CartEntity = yield select((state: RootState) =>
    cartsSelector.selectById(state, currentPageId)
  )

  const printServiceProduct: PrintServiceProductEntity = yield select(
    (state: RootState) =>
      printServiceProductsSelector.selectById(state, productId)
  )

  const printServiceProductImages: PrintServiceProductImageEntity[] =
    yield select((state: RootState) =>
      printServiceProductImagesSelector.selectByIds(
        state,
        printServiceProduct.printServiceProductImages
      )
    )

  const { addOne: addOneLineItem } = lineItems.actions
  const { updateOne: updateCart } = carts.actions

  const requiredProductPages = getPagesFromProduct({
    ...printServiceProduct,
    printServiceProductImages,
  })

  const productPageIds = []
  for (let i = 0; i < requiredProductPages.length; i++) {
    const requiredProductPage = requiredProductPages[i]
    const pageId = yield call(() => createProductPage(requiredProductPage))
    productPageIds.push(pageId)
  }

  const lineItemId = nanoid()

  let orientation =
    printServiceProduct.metaData?.orientationOverride ?? "portrait"
  if (action.payload.createFromProduct) {
    orientation =
      printServiceProduct.width < printServiceProduct.height
        ? "portrait"
        : "landscape"
  }

  const giftCertificateId =
    printServiceProduct.categoryName === "gift certificate"
      ? nanoid()
      : undefined

  const giftCertificateProductId =
    printServiceProduct.categoryName === "gift certificate"
      ? printServiceProduct.id
      : undefined

  //Create gift certificate
  if (giftCertificateId && giftCertificateProductId) {
    yield put(
      giftCertificates.actions.addOne({
        id: giftCertificateId,
        productId: giftCertificateProductId,
        name: "",
        email: "",
        message: "",
      })
    )
  }

  // create line item
  yield put(
    addOneLineItem({
      id: lineItemId,
      productPageIds,
      productId,
      quantity: 1,
      orientation,
      isReadOnly: false,
      renderedUploadIds: [],
      isTemplate: false,
      giftCertificateId,

      // Open editor straight away if a product was selected instead of an image being uploaded
      isEditing: action.payload.createFromProduct ? true : false,
      isReady: action.payload.createFromProduct ? true : false,
      isConfirmed: action.payload.createFromProduct ? false : true,
    })
  )

  // add line item to the current page's cart
  yield put(
    updateCart({
      id: cart.id,
      changes: {
        lineItemIds: [...cart.lineItemIds, lineItemId],
      },
    })
  )

  yield call(
    processAnalyticEvents,
    AnalyticAction({
      eventType: "addOneItemToCart",
      data: { lineItemId },
    })
  )

  yield put(
    actions.updateOrderSummary({
      reason: "new line item created",
      reasonType: "cartChange",
    })
  )

  return lineItemId
}

function* createProductPage(productPageFromProduct: ProductPageEntityHydrated) {
  const requiredProductPage = productPageFromProduct

  const pageId = nanoid()

  const { addOne: addOneImageRegion } = imageRegions.actions
  const { addOne: addOneTextRegion } = textRegions.actions
  const { addOne: addOneProductPage } = productPages.actions

  const imageRegionIds = []
  const textRegionIds = []
  for (let j = 0; j < requiredProductPage.imageRegions.length; j++) {
    const imageRegion = requiredProductPage.imageRegions[j]
    const imageRegionId = nanoid()
    yield put(
      addOneImageRegion({
        id: imageRegionId,
        window: imageRegion.window,
      })
    )
    imageRegionIds.push(imageRegionId)
  }

  for (let j = 0; j < requiredProductPage.textRegions.length; j++) {
    const textRegion = requiredProductPage.textRegions[j]
    const textRegionId = nanoid()
    yield put(
      addOneTextRegion({
        id: textRegionId,
        window: textRegion.window,
        align: textRegion.align,
        color: textRegion.color,
        font: textRegion.font,
        key: textRegion.key,
        placeholder: textRegion.placeholder,
        size: textRegion.size,
        defaultSize: textRegion.defaultSize,
        text: "",
      })
    )
    textRegionIds.push(textRegionId)
  }

  yield put(
    addOneProductPage({
      id: pageId,
      width: requiredProductPage.width,
      height: requiredProductPage.height,
      imageRegionIds,
      textRegionIds,
    })
  )

  return pageId
}

// ====================================================
// create line item from a template
// ====================================================
export function* watchCreateLineItemFromTemplate() {
  yield takeEvery(
    actions.createLineItemFromTemplate.type,
    processCreateLineItemFromTemplate
  )
}

function* processCreateLineItemFromTemplate(
  action: PayloadAction<CreateLineItemFromTemplatePayload>
): SagaIterator<any> {
  const { productTemplateId, productId, blockTemplateId, blockId } =
    action.payload

  const getCurrentPageId = yield getContext("getCurrentPageId")
  const currentPageId = getCurrentPageId()

  const cart: CartEntity = yield select((state: RootState) =>
    cartsSelector.selectById(state, currentPageId)
  )

  const printServiceProduct: PrintServiceProductEntity = yield select(
    (state: RootState) =>
      printServiceProductsSelector.selectById(state, productId)
  )

  const variants = yield call(getProductTemplateVariantForProduct, {
    productId,
    productTemplateId,
  })
  const [firstVariant] = variants
  const [firstProductPage] = firstVariant.variant.pages
  const defaultOrientation =
    firstProductPage.width > firstProductPage.height ? "landscape" : "portrait"

  const orientation =
    printServiceProduct.metaData?.orientationOverride ?? defaultOrientation

  // create or remove product pages based on the template variant
  const productPageIds = yield call(prepareProductPagesForVariant, {
    productPageIds: [],
    variant: firstVariant.variant,
  })

  const { addOne: addOneLineItem } = lineItems.actions
  const { updateOne: updateCart } = carts.actions

  const lineItemId = nanoid()

  // Create line item
  yield put(
    addOneLineItem({
      id: lineItemId,
      productPageIds,
      productId,
      productTemplateId,
      blockTemplateId,
      blockId,
      productTemplateVariantId: firstVariant.variant.key,
      quantity: 1,
      orientation,
      isEditing: true,
      isTemplate: true,
      isConfirmed: false,
      isReadOnly: false,
      isReady: true,
      renderedUploadIds: [],
    })
  )

  // Add line item to the current page's cart
  yield put(
    updateCart({
      id: cart.id,
      changes: {
        lineItemIds: [...cart.lineItemIds, lineItemId],
      },
    })
  )

  yield call(
    processAnalyticEvents,
    AnalyticAction({
      eventType: "addOneItemToCart",
      data: { lineItemId },
    })
  )

  yield put(
    actions.updateOrderSummary({
      reason: "lineItem created from template",
      reasonType: "cartChange",
    })
  )

  return lineItemId
}

export function* watchRemoveLineItem() {
  yield takeEvery(actions.removeLineItem.type, processRemoveLineItem)
}

function* processRemoveLineItem(action: PayloadAction<RemoveLineItemPayload>) {
  const { lineItemId } = action.payload
  const cart: CartEntity = yield call(getCurrentCart)

  const newLineItemIds = cart.lineItemIds.filter(id => id !== lineItemId)

  const lineItem: LineItemEntity = yield select((state: RootState) =>
    lineItemsSelectors.selectById(state, lineItemId)
  )

  if (lineItem.giftCertificateId !== undefined) {
    yield put(giftCertificates.actions.removeOne(lineItem.giftCertificateId))
  }

  if (newLineItemIds.length === 0) {
    const pageSession: PageSessionEntity = yield call(getCurrentPageSession)
    yield put(
      pageSessions.actions.updateOne({
        id: pageSession.id,
        changes: {
          uploadIds: [],
        },
      })
    )
    yield put(uploads.actions.removeAll(null))
  }

  yield put(
    carts.actions.updateOne({
      id: cart.id,
      changes: {
        lineItemIds: newLineItemIds,
      },
    })
  )

  yield call(
    processAnalyticEvents,
    AnalyticAction({
      eventType: "clearItemFromCart",
      data: { lineItemId },
    })
  )

  yield put(lineItems.actions.removeOne(lineItemId))

  yield put(
    actions.updateOrderSummary({
      reason: "lineItem removed",
      reasonType: "cartChange",
    })
  )
}

export function* watchAddLineItemToCart(): SagaIterator {
  const requestChannel = yield actionChannel(actions.addLineItemToCart.type)
  while (true) {
    const { payload } = yield take(requestChannel)
    yield call(addLineItemToCart, payload)
  }
}

export function* watchInsertPageSpreadToLineItemBefore() {
  yield takeEvery(
    actions.insertPageSpreadToLineItemBefore.type,
    processInsertPageSpreadToLineItemBefore
  )
}

export function* watchInsertPageSpreadToLineItemAfter() {
  yield takeEvery(
    actions.insertPageSpreadToLineItemAfter.type,
    processInsertPageSpreadToLineItemAfter
  )
}

function* processInsertPageSpreadToLineItemBefore(
  action: PayloadAction<InsertPageSpreadToLineItemPayload>
): SagaIterator<any> {
  const { lineItemId, productPageId, callback } = action.payload

  const lineItem: LineItemEntity = yield call(() => getLineItem(lineItemId))
  const currentPageIndex = lineItem.productPageIds.indexOf(productPageId)

  // [cover, 0 | 1, 2 | 3, 4 | 5, cover]
  // find smallest odd number
  let insertPosition = currentPageIndex
  const isEven = Number(currentPageIndex) % 2 === 0
  if (isEven) {
    insertPosition = currentPageIndex - 1
  }

  yield call(() =>
    insertPageSpreadToLineItem(lineItemId, insertPosition, callback)
  )
}

function* processInsertPageSpreadToLineItemAfter(
  action: PayloadAction<InsertPageSpreadToLineItemPayload>
): SagaIterator<any> {
  const { lineItemId, productPageId, callback } = action.payload

  const lineItem: LineItemEntity = yield call(() => getLineItem(lineItemId))
  const currentPageIndex = lineItem.productPageIds.indexOf(productPageId)

  // [cover, 0 | 1, 2 | 3, 4 | 5, cover]
  // find the next slot to insert
  let insertPosition = currentPageIndex + 2
  const isEven = Number(currentPageIndex) % 2 === 0
  if (isEven) {
    insertPosition = currentPageIndex + 1
  }

  yield call(() =>
    insertPageSpreadToLineItem(lineItemId, insertPosition, callback)
  )
}

function* insertPageSpreadToLineItem(
  lineItemId: EntityId,
  position: number,
  callback?: (id: EntityId) => void
) {
  const lineItem: LineItemEntity = yield call(() => getLineItem(lineItemId))
  const productId = lineItem.productId

  const printServiceProduct: PrintServiceProductEntity = yield select(
    (state: RootState) =>
      printServiceProductsSelector.selectById(state, productId)
  )

  const printServiceProductImages: PrintServiceProductImageEntity[] =
    yield select((state: RootState) =>
      printServiceProductImagesSelector.selectByIds(
        state,
        printServiceProduct.printServiceProductImages
      )
    )

  const requiredProductPages = getPagesFromProduct({
    ...printServiceProduct,
    printServiceProductImages,
  })

  const firstPage = requiredProductPages[0]

  const newPageOneId: EntityId = yield call(() => createProductPage(firstPage))
  const newPageTwoId: EntityId = yield call(() => createProductPage(firstPage))

  const { productPageIds } = lineItem

  const newProductPageIds = [
    ...productPageIds.slice(0, position),
    newPageOneId,
    newPageTwoId,
    ...productPageIds.slice(position),
  ]

  const { updateOne: updateLineItem } = lineItems.actions

  yield put(
    updateLineItem({
      id: lineItemId,
      changes: {
        productPageIds: newProductPageIds,
      },
    })
  )

  callback && callback(newPageTwoId)
}

export function* watchRemovePageSpreadFromLineItem() {
  yield takeEvery(
    actions.removePageSpreadFromLineItem.type,
    processRemovePageSpreadFromLineItem
  )
}

function* processRemovePageSpreadFromLineItem(
  action: PayloadAction<RemovePageSpreadFromLineItemPayload>
) {
  const { payload } = action

  const { lineItemId, productPageId } = payload
  const lineItem: LineItemEntity = yield call(() => getLineItem(lineItemId))

  // remove the product page id from the line item
  const { productPageIds } = lineItem
  // we remove a full spread, we know that the page id is the right part of the spread
  const previousPageIndex = productPageIds.indexOf(productPageId) - 1
  const previousPageId = productPageIds[previousPageIndex]
  const newProductPageIds = productPageIds.filter(
    id => ![previousPageId, productPageId].includes(id)
  )

  const { updateOne: updateLineItem } = lineItems.actions

  yield put(
    updateLineItem({
      id: lineItemId,
      changes: {
        productPageIds: newProductPageIds,
      },
    })
  )
}
