import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react"
import { AppEntityHydrated } from "@jackfruit/common"
import React from "react"
import { useTranslation } from "react-i18next"
import { RemoveScroll } from "react-remove-scroll"

interface Props {
  appConfig: AppEntityHydrated
  onInstall: () => void
  onCloseBanner: () => void
  isAppleDevice: boolean
  slideIn: boolean
  transitionTime: number
}

const AppBannerMinimal: React.FC<Props> = ({
  appConfig,
  onInstall,
  onCloseBanner,
  isAppleDevice,
  slideIn,
  transitionTime,
}) => {
  const { faviconImage } = appConfig

  const { t } = useTranslation()

  const appName = isAppleDevice
    ? appConfig.iosStoreName
    : appConfig.androidStoreName

  const isVisible =
    useBreakpointValue({
      base: true,
      lg: false,
    }) ?? true

  return (
    <RemoveScroll removeScrollBar={false} enabled={isVisible}>
      <Flex
        id="install-mobile-app-banner"
        bg={slideIn ? "blackAlpha.400" : "transparent"}
        flexDirection="column"
        position="fixed"
        top={0}
        bottom={0}
        left={0}
        right={0}
        overflow="hidden"
        zIndex={11}
        transition={`${transitionTime}ms all`}
        pointerEvents={slideIn ? "all" : "none"}
      >
        <Box
          className="p-mobile-banner-dismiss-action" // GTM: click on mobile banner dismiss action
          flexGrow={1}
          width="full"
          onClick={onCloseBanner}
        />
        <Flex
          flexDirection="row"
          bg="white"
          width="full"
          px={8}
          py={4}
          alignItems="center"
          justifyContent="center"
          mb={slideIn ? 0 : "-26vh"}
          transition={`${transitionTime}ms all`}
        >
          <Image
            className="p-mobile-banner-app-icon" // GTM: click on mobile banner app icon
            src={faviconImage.path}
            p={2}
            my={2}
            boxSize={20}
            htmlWidth={128}
            htmlHeight={128}
            bg="white"
            borderRadius="base"
            ignoreFallback={true}
            boxShadow="md"
          />
          <Flex
            flexDirection="row"
            alignItems="center"
            flexWrap="wrap"
            width="full"
            maxW="585px"
          >
            <Flex flexDirection="column" flexGrow={0} flexShrink={1} ml={6}>
              <Text fontWeight="bold" fontSize={16} mt={2} mb={1}>
                {t("components.AppBanner.Minimal.BannerTitle", { appName })}
              </Text>
              <Text color="gray.400" fontSize={16} mt={0} mx={0} mb={1}>
                {t("components.AppBanner.Minimal.Description")}
              </Text>
            </Flex>

            <Flex flexGrow={1} ml={6} my={1}>
              <Button
                className="p-mobile-banner-dismiss-button" // GTM: click on mobile banner dismiss button
                width="full"
                bg="none"
                color="primary.500"
                border={1}
                borderStyle="solid"
                borderColor="primary.500"
                onClick={onCloseBanner}
                mr={3}
                ml={0}
              >
                {t("components.AppBanner.Minimal.Dismiss")}
              </Button>
              <Button
                width="full"
                bg="primary.500"
                color="white"
                onClick={onInstall}
                className="btn btn-primary" // GTM: click on mobile banner store button
              >
                {t("components.AppBanner.Minimal.Install")}
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </RemoveScroll>
  )
}

export default AppBannerMinimal
