import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Wrapper from "../../../components/Wrapper"
import Head from "../Head"
import Footer from "./Footer"
import Header from "./Header"

interface Props {
  children: React.ReactElement
}

const Layout: React.FC<Props> = ({ children }) => {
  const { configJson } = useStaticQuery(configJsonQuery)

  return (
    <>
      <Head config={configJson} />
      <Header config={configJson} />
      <Wrapper py={{ base: 5, lg: 10 }}>{children}</Wrapper>
      <Footer config={configJson} />
    </>
  )
}

export default Layout

export const configJsonQuery = graphql`
  query GetConfig {
    configJson {
      id
      name
      slug
      baseUrl
      termsAndConditionsPageId
      privacyPolicyPageId
      faviconImageId
      logoImageId
      iosStoreUrl
      iosStoreName
      iosStoreRating
      iosStoreRatingCount
      iosDisplayStoreBannerOnMobile
      androidStoreUrl
      androidStoreName
      androidStoreRating
      androidStoreRatingCount
      androidDisplayStoreBannerOnMobile
      blockOrderSummaryAppInstallEnabled
      blockOrderSummaryAppInstallQrCode
      blockOrderSummaryAppInstallQrCodeUrl
      blockOrderSummaryAppInstallAppScreenImageId
      blockOrderSummaryAppInstallContent
      blockOrderSummaryAppInstallAppScreenImage {
        id
        filename
        path
        hash
        mimeType
        width
        height
        size
      }
      openGraphTitle
      openGraphDescription
      openGraphLogo
      openGraphSitemapXml
      defaultLocale
      defaultCurrency
      language
      common {
        id
        appId
        hideHeader
        hideFooter
        showPoweredByAutopilotBanner
        poweredByAutopilotBannerText
        poweredByAutopilotRedirectUrl
        poweredByAutopilotBg
        poweredByAutopilotTextColour
        showSocialMediaLinks
      }
      faviconImage {
        id
        filename
        path
        hash
        mimeType
        width
        height
        size
      }
      logoImage {
        id
        filename
        path
        hash
        mimeType
        width
        height
        size
      }
      navigations {
        id
        name
        appId
        links {
          id
          label
          labelPrefixHtml
          labelSuffixHtml
          path
          parentId
          navigationId
          navigationMegamenuId
          pageId
          page {
            id
            name
            appId
            order
            slug
            isDeliveryEnabled
            isPickupEnabled
            defaultFulfillment
          }
          type
          external
          order
          megamenu {
            id
            name
            columns {
              id
              title
              order
              navigationMegamenuId
              cells {
                id
                title
                type
                html
                navigationMegamenuColumnId
                order
                links {
                  id
                  external
                  label
                  navigationMegamenuCellId
                  order
                  pageId
                  path
                  type
                  page {
                    id
                    name
                    slug
                  }
                }
              }
            }
          }
        }
      }
      pages {
        parentId
        id
        name
        appId
        order
        slug
        parentId
        blocks {
          id
          pageId
          type
          elementId
          order
          inherit
          footer {
            id
            blockId
            row1Col1Html
            row1Col2Html
            row2Col1Html
            row2Col2Html
            row2Col3Html
            row2Col4Html
            row3Col1Html
            row1Bg
            row2Bg
            row3Bg
          }
          header {
            id
            blockId
            topNavigationBackgroundColor
            topNavigationColor
            brandNavigationBackgroundColor
            brandNavigationColor
            brandNavigationId
            mainNavigationBackgroundColor
            mainNavigationColor
            mainNavigationId
            mobileNavigationColor
            mobileNavigationId
            callToActionText
            displaySiteWideBanner
            siteWideBannerContent
            brandNavigation {
              id
              name
              appId
              links {
                id
                label
                labelPrefixHtml
                labelSuffixHtml
                parentId
                navigationId
                pageId
                type
                order
                path
                page {
                  id
                  name
                  appId
                  order
                  slug
                  isDeliveryEnabled
                  isPickupEnabled
                  defaultFulfillment
                }
              }
            }
            mainNavigation {
              id
              name
              appId
              links {
                id
                label
                labelPrefixHtml
                labelSuffixHtml
                parentId
                navigationId
                pageId
                type
                order
                path
                page {
                  id
                  name
                  appId
                  order
                  slug
                  isDeliveryEnabled
                  isPickupEnabled
                  defaultFulfillment
                }
              }
            }
            mobileNavigation {
              id
              name
              appId
              links {
                id
                label
                labelPrefixHtml
                labelSuffixHtml
                parentId
                navigationId
                pageId
                type
                order
                page {
                  id
                  name
                  appId
                  order
                  slug
                  isDeliveryEnabled
                  isPickupEnabled
                  defaultFulfillment
                }
              }
            }
            topNavigation {
              id
              name
              appId
              links {
                id
                label
                labelPrefixHtml
                labelSuffixHtml
                navigationId
                pageId
                type
                order
                page {
                  id
                  name
                  appId
                  order
                  slug
                  isDeliveryEnabled
                  isPickupEnabled
                  defaultFulfillment
                }
              }
            }
          }
        }
        defaultPickupTerritory
        defaultDeliveryTerritory
        pageSeo {
          id
          pageId
          openGraphTitle
          openGraphDescription
          openGraphLogo
          openGraphFeatureImageId
          openGraphSitemapXml
          openGraphFeatureImage {
            id
            filename
            path
            hash
            mimeType
            width
            height
            size
          }
        }
      }
      settings {
        id
        appId
        remoteApiUrl
        remoteApiClientApiKey
        recaptchaSiteKey
        googleMapKey
        addressAutoComplete
        loggingMode
        mapProvider
        rawHead
        isUserAccountEnabled
        gtmProjectId
        loggingMode
        googleOptimizeExperimentId
      }
    }
  }
`
